<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" label="人员名称" name="name">
            <a-input v-model:value="formState.name" placeholder="请输入人员名称"></a-input>
          </a-form-item>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-button v-permission="['film_performer_add']" type="primary" @click="onAdd">新增人员</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'imageUrl'">
              <a-image :src="record.imageUrl" :width="50"></a-image>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['film_performer_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['film_performer_del']" @click="onDelete(record)">
                      <a-menu-item>
                        刪除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="showModal" :title="modelRef.id ? '修改人员' : '添加人员'" @ok="onSubmit">
        <a-spin :spinning="loading">
          <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{ span: 5 }" :wrapperCol="{span: 16}">
            <a-form-item label="人员名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input v-model:value="modelRef.name" placeholder="请输入人员名称"/>
            </a-form-item>
            <a-form-item label="图片" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议宽高比180:273">
              <a-upload
                  v-model:file-list="fileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import {deleteFilmActor, getFilmActorList, saveFilmActor, updateFilmActor} from "@/service/modules/film";
export default {
  components: { Icon },
  data() {
    return {
      isEdit: false,
      id: 0,
      fileList: [],
      loading: false,
      searchData: {},
      showModal: false,
      modelRef: {},
      formState: {},
      list: [],
      columns: [{
        title: '人员名称',
        dataIndex: 'name',
      }, {
        title: '图片',
        key: 'imageUrl',
      }, {
        title: '操作',
        key: 'action',
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onAdd(){
      this.modelRef = {};
      this.fileList = [];
      this.showModal = true;
      this.isEdit = false;
    },
    onEdit(item){
      this.fileList = [];
      if(item.imageUrl) {
        this.fileList = item.imageUrl.split(',').map(item => {
          return {
            url: item
          }
        })
      }
      this.showModal = true;
      this.modelRef = JSON.parse(JSON.stringify(item));
      this.id = item.id;
      this.isEdit = true;
    },
    // 提交修改数据
    async onSubmit(){
      this.$refs.addForm.validateFields().then(async () => {
        if(this.fileList.length === 0) {
          this.$message.warning('图片不能为空！');
          return;
        }
        this.loading = true;
        let postData = JSON.parse(JSON.stringify(this.modelRef));
        const imgList = [];
        this.fileList.forEach(item => {
          imgList.push(item.url);
        });
        postData.imageUrl = imgList.join(',');
        try {
          let ret;
          if(this.isEdit) {
            postData.id = this.id;
            ret = await updateFilmActor(postData);
          } else {
            ret = await saveFilmActor(postData);
          }
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.modelRef = {};
            this.fileList = [];
            this.showModal = false;
            this.getData();
          }
          this.loading = false;
        } catch(e) {
          this.loading = false;
        }
      })
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确定删除吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteFilmActor({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getFilmActorList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
        if(ret.data.imageUrl) {
          this.fileList = ret.data.imageUrl.split(',').map(item => {
            return {
              url: item
            }
          })
        }
      } catch(e) {
        this.loading = false;
      }
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        //console.log(info.file);
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
